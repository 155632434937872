import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../../core/types"
import { useUserPreferences } from "../user-preferences/use-user-preferences"
import { fetchNotifications } from "./notifications-actions"
import { NotificationModel } from "../../model/notification-model"

export const useNotifications = () => {

    const dispatch = useDispatch()

    const userPreferences = useUserPreferences()
    const notifications = useSelector<ApplicationState,NotificationModel[]>( state => state.notifications.notifications )

    useEffect( () => {
        if (userPreferences) {
            dispatch(fetchNotifications())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPreferences])

    return notifications
}
